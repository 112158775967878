<template>
  <el-drawer
    :title="drawerTitle"
    custom-class="g-drawer"
    :wrapper-closable="false"
    :visible="drawerVisible"
    size="600px"
    @open="open"
    @close="close"
  >
    <div class="g-drawer-c">
      <el-form ref="form" :model="drawerForm" :rules="drawerRules" :disabled="formDisabled" label-position="right" label-width="110px">
        <el-form-item label="关联用户" prop="ext1">
          <div style="line-height: 15px;width: 70%;">
            <UserInfoCard :need-change-user-info="false" :need-match="false" :user-info="companyInfo" :show-bg="true" />
          </div>
        </el-form-item>

        <el-row :gutter="0">
          <el-col :span="12">
            <el-form-item label="挂车号" prop="truckNo">
              <el-input v-model="drawerForm.truckNo" :disabled="drawerType!=='add'" placeholder="请输入" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="荷载" prop="truckLoadTon">
              <el-input v-model="drawerForm.truckLoadTon" placeholder="请输入" clearable maxlength="16" @input="validateNumber('truckLoadTon', 16)">
                <template v-slot:append>
                  <span class="unit">吨</span>
                </template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="0">
          <el-col :span="12">
            <el-form-item label="牵引挂类型" prop="truckType">
              <el-select v-model="drawerForm.truckType" class="g-inp" clearable placeholder="请选择">
                <el-option v-for="item in dictTruckHangMap" :key="item.value" :label="item.label" :value="item.value" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="罐体类别" prop="tankType">
              <el-select v-model="drawerForm.extMap.tankType" class="g-inp" clearable placeholder="请选择">
                <el-option v-for="item in dictTruckTankTypeMap" :key="item.value" :label="item.label" :value="item.value" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="罐体允装介质" prop="tankFullType">
          <el-input
            v-model="drawerForm.extMap.tankFullType"
            type="textarea"
            :rows="2"
            placeholder="请输入"
            clearable
          />
        </el-form-item>
        <el-row :gutter="0">
          <el-col :span="12">
            <el-form-item label="罐体材质" prop="tankMaterial">
              <el-select v-model="drawerForm.extMap.tankMaterial" class="g-inp" clearable placeholder="请选择">
                <el-option v-for="item in dictTruckTankMaterialMap" :key="item.value" :label="item.label" :value="item.value" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="罐体容积" prop="tankVolume">
              <el-input v-model="drawerForm.extMap.tankVolume" placeholder="请输入" clearable @input="validateNumberMultilevel('extMap','tankVolume',6,3)">
                <template v-slot:append>
                  <span class="unit">m³</span>
                </template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <!-- <div class="division-title">行驶证</div> -->
        <el-form-item label="行驶证有效期止" prop="truckLicDestroyTime">
          <el-date-picker
            v-model="drawerForm.extMap.truckLicDestroyTime"
            value-format="timestamp"
            type="date"
            placeholder="请选择"
            :picker-options="pickerOptions"
          />
        </el-form-item>
        <el-form-item label="行驶证照片" prop="truckLicPic">
          <ImageUpload
            :model-value.sync="drawerForm.extMap.truckLicPic"
            placeholder="行驶证照片"
            :mode="formDisabled ? 'preview' : 'edit'"
          />
        </el-form-item>
        <!-- <div class="division-title">道路运输证</div> -->
        <el-form-item label="道路运输证有效期止" prop="roadLicExpireTime">
          <el-date-picker
            v-model="drawerForm.extMap.roadLicExpireTime"
            value-format="timestamp"
            type="date"
            placeholder="请选择"
            :picker-options="pickerOptions"
          />
        </el-form-item>
        <el-form-item label="道路运输证照片" prop="roadLicPic">
          <ImageUpload
            :model-value.sync="drawerForm.extMap.roadLicPic"
            placeholder="道路运输证照片"
            :mode="formDisabled ? 'preview' : 'edit'"
          />
        </el-form-item>
        <!-- <div class="division-title">罐体信息</div> -->
        <el-form-item label="罐检证有效期止" prop="tankCheckExpireTime">
          <el-date-picker
            v-model="drawerForm.extMap.tankCheckExpireTime"
            value-format="timestamp"
            type="date"
            placeholder="请选择"
            :picker-options="pickerOptions"
          />
        </el-form-item>
        <el-form-item label="罐检证照片" prop="tankCheckPic">
          <ImageUpload
            :model-value.sync="drawerForm.extMap.tankCheckPic"
            placeholder="罐检证照片"
            :mode="formDisabled ? 'preview' : 'edit'"
          />
        </el-form-item>
        <el-form-item label="保险有效期止" prop="insuranceExpireTime">
          <el-date-picker
            v-model="drawerForm.extMap.insuranceExpireTime"
            value-format="timestamp"
            type="date"
            placeholder="请选择"
            :picker-options="pickerOptions"
          />
        </el-form-item>
        <el-form-item label="保险照片" prop="insurancePic">
          <ImageUpload
            :model-value.sync="drawerForm.extMap.insurancePic"
            placeholder="保险照片"
            :mode="formDisabled ? 'preview' : 'edit'"
          />
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input v-model="drawerForm.remark" type="textarea" placeholder="请输入" clearable />
        </el-form-item>
      </el-form>
    </div>
    <div class="g-btn-c">
      <el-button @click="close">取消</el-button>
      <el-button v-if="drawerTypeDesc==='add'||drawerTypeDesc==='edit'" type="primary" @click="submit">提交</el-button>
      <el-button v-if="drawerTypeDesc==='detail'" type="danger" @click="deleteClick">删除</el-button>
      <el-button v-if="drawerTypeDesc==='detail'" type="primary" @click="changeEdit">编辑</el-button>
    </div>
  </el-drawer>
</template>

<script>
import { validateGuaCarNO } from '@/utils/validateRule';
import { addTrailer, editTrailer, getDetailTrailer, deleteTrailer } from '@/api/trailer';
export default {
  props: {
    // 详情类型 add 新增 edit编辑 detail详情
    drawerType: {
      type: String,
      default() {
        return 'add';
      },
    },
    drawerVisible: {
      type: Boolean,
      default() {
        return false;
      },
    },
    drawerId: {
      type: [String, Number],
      default() {
        return '';
      },
    },
    companyInfo: {
      type: Object,
      default() {
        return {
          // companyId: null,
        };
      },
    },
  },
  data() {
    return {
      dictTruckHangMap: [], // 牵引挂类型
      dictTruckTankTypeMap: [], // 罐体类别
      dictTruckTankMaterialMap: [], // 罐体材质
      drawerTypeDesc: '',
      drawerTitleObj: {
        'add': '新增挂车',
        'edit': '编辑挂车',
        'detail': '查看挂车',
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now();
        },
      },
      drawerForm: {
        truckNo: '',
        extMap: {
          truckLicDestroyTime: '', // 3行驶证有效期止
          truckLicPic: '', // 4行驶证照片
          roadLicExpireTime: '', // 5道路运输证有效期止
          roadLicPic: '', // 6道路运输证照片
          tankFullType: '', // 7罐体 允装介质
          tankType: '', // 8罐体类别
          tankMaterial: '', // 9罐体材质
          tankVolume: '', // 10罐体容积
          tankCheckExpireTime: '', // 11罐检证有效期止
          tankCheckPic: '', // 12罐检证照片
        },
        // truckLoadTon: '', // 1荷载
        // truckType: '', // 2牵引挂类型
      },
      drawerRules: {
        truckNo: [
          { required: true, message: '请输入挂车号', trigger: 'change' },
          { validator: validateGuaCarNO, trigger: 'change' },
        ],
        truckLoadTon: [
          { required: true, message: '请输入荷载', trigger: 'change' },
          // { validator: validateGuaCarNO, trigger: 'change' },
        ],
      },
    };
  },
  computed: {
    formDisabled() {
      return this.drawerTypeDesc === 'detail';
    },
    drawerTitle() {
      return this.drawerTitleObj[this.drawerTypeDesc];
    },
  },
  watch: {
    // 监听路由变化，如果发生变化则关闭drawer
    '$route': function(to, from) {
      this.drawerVisible = false;
    },
  },
  methods: {
    open() {
      this.getDictionaryList();
      this.drawerTypeDesc = this.drawerType;
      if (this.drawerTypeDesc === 'detail' || this.drawerTypeDesc === 'edit') {
        this.getDetail();
      }
    },
    close() {
      this.drawerForm = {
        resType: '1',
        extMap: {
          truckLicDestroyTime: '', // 3行驶证有效期止
          truckLicPic: '', // 4行驶证照片
          roadLicExpireTime: '', // 5道路运输证有效期止
          roadLicPic: '', // 6道路运输证照片
          tankFullType: '', // 7罐体 允装介质
          tankType: '', // 8罐体类别
          tankMaterial: '', // 9罐体材质
          tankVolume: '', // 10罐体容积
          tankCheckExpireTime: '', // 11罐检证有效期止
          tankCheckPic: '', // 12罐检证照片
        },
        // truckLoadTon: '', // 1荷载
        // truckType: '', // 2牵引挂类型
      };
      this.$refs.form.resetFields();
      this.$emit('update:drawerVisible', false);
    },
    // 获取数据字典数组
    async getDictionaryList() {
      const params = ['dict_truck_hang', 'dict_tank_type', 'dict_tank_material'];
      const data = await this.$store.dispatch('dictionary/get', params);
      if (data) {
        this.dictTruckHangMap = data['dict_truck_hang']?.A || [];
        this.dictTruckTankTypeMap = data['dict_tank_type']?.A || [];
        this.dictTruckTankMaterialMap = data['dict_tank_material']?.A || [];
      }
    },
    getDetail() {
      getDetailTrailer({ truckId: this.drawerId }).then(res => {
        this.drawerForm = res || {};
      });
    },
    deleteClick() {
      const params = {
        id: this.drawerId,
      };
      deleteTrailer(params).then(res => {
        this.$baseMessage('删除成功!', 'success');
        this.$emit('drawerSubmit');
        this.close();
      });
    },
    changeEdit() {
      this.drawerTypeDesc = 'edit';
    },
    submit() {
      // type 获取type 分别对应api提交
      this.$refs.form.validate(async(valid) => {
        if (valid) {
          try {
            const drawerForm = JSON.parse(JSON.stringify(this.drawerForm));
            const apiMap = {
              'add': addTrailer,
              'edit': editTrailer,
            };
            const res = await apiMap[this.drawerTypeDesc](drawerForm);
            if (res) {
              this.$baseMessage(`${this.drawerTitle}成功!`, 'success');
              this.$emit('drawerSubmit');
              this.close();
            }
          } catch (error) {
            console.log(error);
          }
        }
      });
    },
    validateNumber(val, limitNum, decimals) {
      // 正则限制输入的金额不能为中文、特殊字符、两位小数
      const reg = /^[0-9]+.?[0-9]*/;
      // 如果不符合正则，提示
      if (!reg.test(+this.drawerForm[val])) {
        this.drawerForm[val] = '';
      }
      // 保留两位小数
      const price = this.drawerForm[val].toString();
      const pArr = price.split('.');
      pArr[0] = pArr[0].substr(0, limitNum);
      if (pArr.length > 1 && decimals) {
        this.drawerForm[val] = pArr[0] + '.' + pArr[1].substr(0, decimals);
      } else {
        this.drawerForm[val] = pArr[0];
      }
    },
    validateNumberMultilevel(pval, cval, limitNum, decimals) {
      // 正则限制输入的金额不能为中文、特殊字符、两位小数
      const reg = /^[0-9]+.?[0-9]*/;
      // 如果不符合正则，提示
      if (!reg.test(+this.drawerForm[pval][cval])) {
        this.drawerForm[pval][cval] = '';
      }
      // 保留两位小数
      const price = this.drawerForm[pval][cval].toString();
      const pArr = price.split('.');
      pArr[0] = pArr[0].substr(0, limitNum);
      if (pArr.length > 1 && decimals) {
        this.drawerForm[pval][cval] = pArr[0] + '.' + pArr[1].substr(0, decimals);
      } else {
        this.drawerForm[pval][cval] = pArr[0];
      }
    },
  },
};

</script>
<style lang='scss' scoped>
</style>
