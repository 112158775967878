import request from '@/plugins/axios';
// function pickTrue(obj) {
//   const newObj = {
//     ...obj,
//   };
//   Object.keys(newObj).forEach((item) => {
//     if (!newObj[item] && newObj[item] !== 0) {
//       delete newObj[item];
//     }
//   });
//   return newObj;
// }
/* ----------【all】分页车辆------------ */
export const getTrailerOld = (data) => {
  return request.post(`/resource/gua/list`, data).then((res) => {
    return res;
  });
};
export const getTrailer = (data) => {
  return request.post(`/resource/middle/gua/list`, data).then((res) => {
    return res;
  });
};
export const addTrailer = (data) => {
  return request.post('/resource/gua/add', data, { showLoading: true }).then((res) => {
    return res;
  });
};
export const getDetailTrailer = (data) => {
  return request.get('/resource/gua/detail', data).then((res) => {
    return res;
  });
};
export const editTrailer = (data) => {
  return request.post('/resource/gua/update', data, { showLoading: true }).then((res) => {
    return res;
  });
};
export const deleteTrailer = (data) => {
  return request.post('/resource/gua/delete', data, { showLoading: true }).then((res) => {
    return res;
  });
};
export const batchDeleteTrailer = (data) => {
  return request.post('/resource/gua/batch/delete', data, { showLoading: true }).then((res) => {
    return res;
  });
};
// 车辆状态数量统计
export const carStateTotal = (data) => {
  return request.post('/resource/gua/state/total', data, { showLoading: true }).then((res) => {
    return res;
  });
};
