<template>
  <div class="basic-data-wrap flex">
    <leftPanel ref="leftPanel" :class="['left-wrap',isFold?'left-half':'left-all']" :is-fold.sync="isFold" :company-type="2" @changeCompany="changeCompany" @leftCompanyData="leftCompanyData" />
    <div :class="['right-wrap g-search-form-table',isFold?'right-half':'right-all']">
      <search-form id="search-form" :model="form" :count="2" label-width="auto" :collapse="false" @search="searchHandle">
        <search-item label="" prop="truckNo">
          <el-input v-model.trim="form.truckNo" clearable placeholder="挂车号" />
        </search-item>
        <search-item label="" prop="truckType">
          <el-select v-model="form.truckType" clearable placeholder="牵引挂类型">
            <el-option v-for="item in dictTruckHangData.A" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
        </search-item>
      </search-form>
      <Table
        :loading="loading"
        :table-height="tableHeight"
        :show-selection-change="true"
        :table-cols="tableCols"
        :table-data="tableData"
        :table-btns="tableBtns"
        :pagination="pagination"
        :multiple-selection.sync="multipleSelection"
        @selectTime="selectTime"
        @sizeChange="sizeChange"
        @pageChange="pageChange"
      >
        <template v-slot="{ col, row }">
          <div v-if="col.prop === 'truckType'">
            <!-- <template v-if="row.extMap"> -->
            {{ dictTruckHangData.O[row.truckType]||'-' }}
          <!-- </template> -->
          </div>
          <div v-if="col.prop === 'tankType'">
            <template v-if="row.extMap">
              {{ dictTruckTankTypeData.O[row.extMap.tankType]||'-' }}
            </template>
            <template v-else>
              -
            </template>
          </div>
          <div v-if="col.prop === 'tankMaterial'">
            <template v-if="row.extMap">
              {{ dictTruckTankMaterialData.O[row.extMap.tankMaterial]||'-' }}
            </template>
            <template v-else>
              -
            </template>
          </div>
        </template>
      </Table>
      <Drawer :drawer-visible.sync="drawerVisible" :drawer-type="drawerType" :drawer-id="id" :company-info="companyInfo" @drawerSubmit="drawerSubmit" />
    </div>
  </div>
</template>

<script>
import { getTrailer, batchDeleteTrailer } from '@/api/trailer';
import { exportByJson, parseTime } from '@/utils';
import leftPanel from '../components/leftPanel';
import Drawer from './drawer';
export default {
  name: 'BasicdataTrailer',
  components: { leftPanel, Drawer },
  data() {
    return {
      isFold: false,
      tableHeight: 'auto',
      companyInfo: {}, // 当前所选的左侧公司信息 相关接口需要此参数
      loading: false,
      dictTruckHangData: {
        A: [],
        O: {},
      }, // 牵引挂类型
      dictTruckTankTypeData: {
        A: [],
        O: {},
      }, // 罐体类别
      dictTruckTankMaterialData: {
        A: [],
        O: {},
      }, // 罐体材质
      form: {},
      tableBtns: [
        {
          label: '新增挂车',
          icon: 'el-icon-circle-plus-outline',
          evt: () => {
            this.openDialog('add');
          },
        },
        {
          label: '删除',
          type: 'danger',
          evt: () => {
            this.delete();
          },
        },
        {
          label: '导入',
          type: 'default',
          evt: () => {
            this.onExport();
          },
        },
        {
          label: '导出',
          type: 'default',
          evt: () => {
            this.onExport();
          },
        },
      ],
      tableCols: [
        { label: '挂车号', prop: 'truckNo', fixed: 'left', type: 'jump', evt: (data) => { this.openDialog('detail', data); } },
        { label: '牵引挂类型', prop: 'truckType', type: 'customize' },
        { label: '荷载(吨)', prop: 'truckLoadTon' },
        { label: '允装介质', type: 'objShow', objShow: { keyPar: 'extMap', keyChild: 'tankFullType' }},
        { label: '罐体类别', prop: 'tankType', type: 'customize' },
        { label: '罐体材质', prop: 'tankMaterial', type: 'customize' },
        { label: '罐体容积(m³)', type: 'objShow', objShow: { keyPar: 'extMap', keyChild: 'tankVolume' }},
        { label: '创建人', prop: 'createUsername' },
        { label: '创建时间 ', prop: 'createTime', type: 'filterTime', width: '160' },
        { label: '更新人', prop: 'updateUsername' },
        { label: '更新时间', prop: 'updateTime', sortable: 'custom', headTimeSelect: true, width: 170, type: 'filterTime' },
        { label: '操作', type: 'operation', fixed: 'right',
          btns: [
            {
              label: '编辑',
              evt: (data) => {
                this.openDialog('edit', data);
              },
            },
          ],
        },
      ],
      tableData: [],
      multipleSelection: [],
      pagination: {
        page: 1,
        size: 10,
        total: 0,
      },
      id: null,
      drawerType: 'add',
      drawerVisible: false,
    };
  },
  created() {
    this.getDictionaryList();
  },
  mounted() {
    this.$nextTick(() => {
      this.handleResize();
    });
    window.addEventListener('resize', this.handleResize, true);
  },
  deactivated() {
    window.removeEventListener('resize', this.handleResize, true);
  },
  methods: {
    handleResize() {
      const screenHeight = document.documentElement.clientHeight;
      this.formHeight = document.getElementById('search-form').clientHeight;
      const otherHeight = this.formHeight + 14 + 82 + 20 + 56 + 52 + 20;
      this.tableHeight = screenHeight - otherHeight + 'px';
    },
    // 获取数据字典数组
    async getDictionaryList() {
      const params = ['dict_truck_hang', 'dict_tank_type', 'dict_tank_material'];
      const data = await this.$store.dispatch('dictionary/get', params);
      if (data) {
        this.dictTruckHangData = data['dict_truck_hang'];
        this.dictTruckTankTypeData = data['dict_tank_type'];
        this.dictTruckTankMaterialData = data['dict_tank_material'];
      }
    },
    // 获取列表数据
    getList() {
      this.loading = true;
      const queryForm = JSON.parse(JSON.stringify(this.form));
      const params = {
        createUser: this.$store.state.common.AUV_USER_ID,
        ...queryForm,
        current: this.pagination.page,
        size: this.pagination.size,
      };
      getTrailer(params).then(res => {
        this.tableData = res?.records || [];
        this.pagination = {
          page: res?.current || 1,
          size: res?.size || 10,
          total: res?.total || 0,
        };
      }).finally(() => {
        this.loading = false;
      });
    },
    // 查询按钮操作
    searchHandle() {
      // 防止点击重置置空以下参数影响筛选
      this.form.companyId = this.companyInfo.companyId; // 企业id
      this.getList();
    },
    sizeChange(size) {
      this.pagination.page = 1;
      this.pagination.size = size;
      this.getList();
    },
    pageChange(page) {
      this.pagination.page = page;
      this.getList();
    },
    // 删除所选
    delete() {
      if (!this.multipleSelection.length) {
        this.$baseMessage('您还未勾选,请先勾选!', 'error');
        return;
      }
      const params = {
        ids: this.multipleSelection.map(d => d.id) || null,
      };
      batchDeleteTrailer(params).then(res => {
        this.$baseMessage('删除成功!', 'success');
        this.getList();
      });
    },
    // 前端导出excel
    onExport() {
      const queryForm = JSON.parse(JSON.stringify(this.form));
      const params = {
        createUser: this.$store.state.common.AUV_USER_ID,
        ...queryForm,
        current: 1,
        size: 9999,
      };
      getTrailer(params).then(res => {
        if (!res) {
          this.$baseMessage('暂无数据可导出!', 'error');
          return;
        }
        const header = ['车牌号码', '牵引挂类型', '荷载(吨)', '允装介质', '罐体类别', '罐体材质', '罐体容积(m³)', '创建人', '创建时间', '更新人', '更新时间'];
        const exportData = res.records.map((item) => {
          return {
            truckNo: item.truckNo,
            truckType: this.dictTruckHangData.O[item.truckType] || '-',
            truckLoadTon: item.truckLoadTon || '-',
            tankFullType: item.extMap ? item.extMap.tankFullType : '-',
            tankType: item.extMap ? this.dictTruckTankTypeData.O[item.extMap.tankType] : '-',
            tankMaterial: item.extMap ? this.dictTruckTankMaterialData.O[item.extMap.tankMaterial] : '-',
            tankVolume: item.extMap ? item.extMap.tankVolume : '-',
            createUsername: item.createUsername,
            createTime: parseTime(item.updateTime),
            updateUsername: item.updateUsername,
            updateTime: parseTime(item.updateTime),
          };
        });
        exportByJson(exportData, header, '挂车列表.xlsx');
      }).catch((err) => {
        console.log(err);
        this.$baseMessage('导出失败', 'error');
      });
    },
    selectTime(time) {
      this.form.updateTimeBegin = time ? time[0] : '';
      this.form.updateTimeEnd = time ? time[1] : '';
      this.getList();
    },
    // 打开侧面抽屉 进行新增/编辑/查看操作
    openDialog(type, data) {
      this.id = data?.id || null;
      this.drawerType = type;
      this.drawerVisible = true;
    },
    // 抽屉提交事件
    drawerSubmit() {
      this.getList();
    },
    // left返回的id
    leftCompanyData(data) {
      this.companyInfo = data;
      this.form.companyId = data.companyId;
      this.getList();
    },
    // 左侧切换公司 重新发起请求
    changeCompany(data) {
      this.companyInfo = data;
      this.form.companyId = data.companyId;
      this.getList();
    },
  },
};

</script>
<style lang='scss' scoped>
@import "@/assets/scss/basicData.scss";
</style>
